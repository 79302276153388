
export function isTimeOverlapped(list, item) {
  if (list.length === 0) {
    return null;
  }

  const { begDate, endDate } = item;
  const itemBeg = new Date(begDate + '/01')
  const itemEnd = new Date(endDate + '/01')

  for (let i = 0; i < list.length; i++) {
    if (list[i].idx === item.idx) {
      continue;
    }
    
    if ((itemEnd < new Date(list[i].begDate + '/01'))
      || (itemBeg > new Date(list[i].endDate + '/01'))) {
        continue;
    } else {
      return list[i];
    }
  }

  return null;
}

export function isTimeReversal(item) {
  const { begDate, endDate } = item;
  // 兼容ios格式
  const itemBeg = new Date(begDate + '/01')
  const itemEnd = new Date(endDate + '/01')
  return itemBeg > itemEnd ? true : false;
}