<template>
  <div :class="$style.title">
    <span>{{ text }}</span>
    <img v-if="icon" @click="$emit('tips')" :class="$style.iconSize" src="@/assets/imgs/calc/link.png" />
  </div>
</template>

<script>
export default {
  props: ["text", "icon"],
};
</script>

<style module>
.title {
  font-size: 16px;
  color: #808080;
  width: 200px;
}

.iconSize {
  width: 18px;
  height: 18px;
  margin-top: 3px;
}
</style>