<template>
  <div>
    <calc-page>
      <template slot="topBox">
        <round-add-container
          title="补缴间断时间和档次"
          @add="onAdd"
          :list="list"
        >
          <div class="center">
            <div v-for="(item, idx) in list" :key="idx">
              <div class="item-box" @click="onUpdate(item)">
                <div class="time-box">
                  <div>{{ item.begDate }}</div>
                  <span></span>
                  <div>{{ item.endDate }}</div>
                </div>
                <div class="row-box">
                  <title-item text="缴费档次"></title-item>
                  <div>{{ item.level * 100 + "%" }}</div>
                </div>
              </div>
              <van-divider />
            </div>
          </div>
        </round-add-container>
      </template>

      <template slot="footerBtn">
        <div class="btn-box" @click="onCalc">开始计算</div>
      </template>
    </calc-page>
    <personal-dialog ref="overlay"></personal-dialog>
  </div>
</template>

<script>
import { Divider } from "vant";
import { calcPersonArrears } from "@/api/tool";
import CalcPage from "@/components/CalcPage";
import TitleItem from "@/views/calc/TitleItem";
import { mapGetters } from "vuex";
import RoundAddContainer from "@/views/calc/components/RoundAddContainer";
import to from "@/utils/to";
import PersonalDialog from "./PersonalDialog";
export default {
  components: {
    CalcPage,
    TitleItem,
    RoundAddContainer,
    [Divider.name]: Divider,
    PersonalDialog,
  },
  data() {
    return {
      loading: false,
      isShow: false,
    };
  },
  computed: {
    list() {
      return this.getAnnuityPersonal().personalGapList;
    },
  },
  methods: {
    onAdd() {
      this.$refs.overlay.open();
    },
    onUpdate(item) {
      this.$refs.overlay.open(item);
    },
    async onCalc() {
      console.log(this.list);
      if (this.list.length <= 0) {
        this.$showInfo("请先添加间断时间和档次");
        return;
      }

      let data = [];
      this.list.forEach((item) => {
        let ele = {};
        ele.begDate = item.begDate + "/01";
        ele.endDate = item.endDate + "/01";
        ele.level = item.level;

        data.push(ele);
      });

      this.$showLoading("计算中...");
      let [err, res] = await to(calcPersonArrears(data));
      this.$hideLoading();

      if (err) {
        this.$toast.fail("计算失败");
        return;
      }

      this.$router.push({
        name: "PensionSupplementPersonalResultPage",
        query: { res: JSON.stringify(res) },
      });
    },
    ...mapGetters("calc", ["getAnnuityPersonal"]),
  },
};
</script>

<style scoped>
.top-btn {
  height: 50px;
  background: #dbf0ff;
  border-radius: 12px 12px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 22px;
  color: #658CF1;
}

.img-box {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.center {
  min-height: 80px;
}

.center >>> .van-divider {
  margin: 0;
}

.row-box {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #333333;
}

.time-box {
  display: flex;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #333333;
  align-items: center;
}

.time-box span {
  width: 16px;
  height: 2px;
  background: #999999;
  border-radius: 1px;
  margin: 0 4px;
}

.btn-box {
  position: fixed;
  bottom: 10px;
  background:#658CF1;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #ffffff;
  display: flex;
  padding: 10px 134px;
}
</style>